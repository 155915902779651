import { IFilterGroup, IFilterItem } from '../interfaces/shop';

const FILTER_GROUPS_DATA = {
  categories: {
    name: 'Categories',
    filters: [
      {
        label: 'Women',
        filterUrlPath: '/shop/women',
      },
      {
        label: 'Men',
        filterUrlPath: '/shop/male',
      },
      {
        label: 'Kids',
        filterUrlPath: '/shop/kids',
      },
      {
        label: 'Vegan',
        filterUrlPath: '/shop/vegan',
      },
    ],
  } as IFilterGroup,
  byType: {
    name: 'By Type',
    filters: [
      {
        label: 'Personalised vitamins',
        filterUrlPath: '/shop/personalised',
      },
      {
        label: 'All vitamins',
        filterUrlPath: '/shop/vitamins',
      },
      {
        label: 'DNA & Blood tests',
        filterUrlPath: '/shop/tests',
      },
      {
        label: 'Effervescents',
        filterUrlPath: '/shop/effervescents',
        tag: 'New',
      },
      {
        label: 'Travel packs',
        filterUrlPath: '/shop/travel-packs',
      },
      {
        label: 'Accessories',
        filterUrlPath: '/shop/special-offers',
      },
      {
        label: 'Digital health products',
        filterUrlPath: '/shop/digital-health-products',
      },
      {
        label: 'Health Bundles',
        filterUrlPath: '/shop/bundles',
      },
    ],
  } as IFilterGroup,
  benefits: {
    name: 'Benefits',
    filters: [
      {
        label: 'Energy',
        filterUrlPath: '/shop/energy',
      },
      {
        label: 'Hair, Skin & Nails',
        filterUrlPath: '/shop/hair-skin-nails',
      },
      {
        label: 'Sleep',
        filterUrlPath: '/shop/sleep',
      },
      {
        label: 'Immunity',
        filterUrlPath: '/shop/immunity',
      },
      {
        label: 'Brain/Focus',
        filterUrlPath: '/shop/brain-focus',
      },
      {
        label: 'Stress',
        filterUrlPath: '/shop/stress',
      },
      {
        label: 'Digestion',
        filterUrlPath: '/shop/digestion',
      },
      {
        label: 'Pre and postnatal',
        filterUrlPath: '/shop/pre-postnatal',
      },
      {
        label: 'Menopause',
        filterUrlPath: '/shop/menopause',
      },
      {
        label: 'Bone & joint',
        filterUrlPath: '/shop/bone_joint',
      },
      {
        label: 'Recovery',
        filterUrlPath: '/shop/recovery',
      },
      {
        label: 'Fertility',
        filterUrlPath: '/shop/fertility',
      },
    ],
  } as IFilterGroup,
  shop: {
    label: 'shop',
    filterUrlPath: '/shop',
  } as IFilterItem,
};

export const FILTER_GROUP_CATEGORY_COLLECTION = [
  ...FILTER_GROUPS_DATA.categories.filters,
  ...FILTER_GROUPS_DATA.byType.filters,
];

export const FILTER_GROUP_BENEFITS_COLLECTION = [...FILTER_GROUPS_DATA.benefits.filters];

export const FILTER_GROUP_COLLECTION = [...FILTER_GROUP_CATEGORY_COLLECTION, ...FILTER_GROUP_BENEFITS_COLLECTION];

export const MOBILE_FILTER_GROUPS = {
  categories: 'categories',
  benefits: 'benefits',
};

export default FILTER_GROUPS_DATA;
