export const MENU_DATA = [
  {
    label: 'Shop',
    items: [
      { label: 'Personalised vitamins', link: '/shop/personalised' },
      { label: 'All vitamins', link: '/shop/vitamins' },
      { label: 'DNA & Blood tests', link: '/shop/tests' },
      { label: 'Effervescents', link: '/shop/effervescents', tag: 'New' },
      { label: 'Health Bundles', link: '/shop/bundles' },
      { label: 'Accessories', link: '/shop/special-offers' },
      { label: 'Shop all', link: '/shop' },
    ],
  },
  {
    label: 'Learn',
    items: [
      { label: 'About Us', link: '/about' },
      { label: 'Blog', link: `${process.env.GATSBY_HOST_NAME!}/blog` },
      { label: 'Science', link: '/science' },
      { label: ' FAQs', link: '/contact' },
    ],
  },
];
